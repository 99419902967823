<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          {{ $t('messages.permission') }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="permissions.data"
          :server-items-length="permissions.total"
          :options.sync="pagination"
          :items-per-page="parseInt(permissions.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          must-sort
          class="elevation-1"
          @update:options="getPermissions"
        >
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import authHeader from '@/utils'
import pagination from '@/mixins/pagination'

export default {
  name: 'Permission',
  mixins: [pagination],
  data () {
    return {
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.name'), value: 'display_name' },
        { text: this.$t('messages.description'), value: 'description' },
        { text: this.$t('messages.system_name'), value: 'name' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' }
      ],
      permissions: {
        data: []
      }
    }
  },
  mounted () {
    this.getPermissions()
  },
  methods: {
    getPermissions () {
      this.$http
        .get('/permissions' + this.datatableOptions, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then((response) => {
          this.loading = false
          this.permissions = response.data
        })
    }
  }
}
</script>
