import { assocPath } from 'ramda'

export default {
  data: () => ({
    pagination: {
      sortDesc: [true],
      page: 1,
      itemsPerPage: parseInt(10),
      sortBy: ['id']
    }
  }),
  methods: {
    /**
     * Receive filter fields and return prepared gql input args
     * @param filterableFields
     * @param query
     * @returns {*}
     */
    gqlFilter (filterableFields, query) {
      Object.entries(filterableFields).forEach(
        ([key, value]) => {
          if (value !== null && value !== '') {
            query = assocPath(['filter', key], value, query)
          }
        }
      )
      return query
    }
  },
  computed: {
    APISorting () {
      return `&direction=${this.pagination.sortDesc[0] === true ? 'desc' : 'asc'}&sort=${this.pagination.sortBy[0]}`
    },
    APIPagination () {
      return `?page=${this.pagination.page}&take=${this.pagination.itemsPerPage}`
    },
    datatableOptions () {
      return this.APIPagination + this.APISorting
    }
  }
}
